import React from 'react';
import './Layout.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { NAVIGATION } from 'config';

import { pageInfoActions } from 'app/redux/actions';

const mapStateToProps = (state) => {
    const { pageInfo, auth } = state;
    return { pageInfo, auth }
}

const actionCreators = {
    changeLang: pageInfoActions.change_lang
}

const ScreenWithNavBar = props => {

    return (
        <div className="layout-navbar">

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

        </div>
    )
}

const ScreenWithNavBarAndFooter = props => {

    return (
        <div className="layout-navbar">

            {/* Top Navigation Bar */}
            <TopNavBar />

            {/* Page Content */}
            <div className="site-content">
                {props.children}
            </div>

            {/* Footer */}
            <Footer />
        </div>
    )
}

const TopNavBar = connect(mapStateToProps, actionCreators)(props => {
    const location = useLocation();
    const { pageInfo, changeLang } = props;
    const lang = pageInfo.display_lang;
    const { isLogin, user } = props.auth;

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top shadow top-navbar">
            <div className="container-xl">
                <button className="navbar-toggler me-lg-auto order-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                    <i className="far fa-bars"></i>
                </button>
                <Link className="navbar-brand order-2" to={NAVIGATION.DASHBOARD}>{pageInfo.company_shortname}</Link>
                <div className="collapse navbar-collapse order-4 order-lg-3" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto me-auto">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#benefit">Benefits</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#collection">Collection</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#team">Our Team</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#faq">FAQ</a>
                        </li>
                    </ul>
                </div>
                <ul className="navbar-nav ms-auto order-3 order-lg-4">
                    <li className="nav-item">
                        <a className="btn btn-primary nav-link" href="#contact">Contact us</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
})

const Footer = connect(mapStateToProps, actionCreators)(props => {
    const { pageInfo } = props;
    const lang = pageInfo.display_lang;
    
    return (
        <footer className="site-footer">
            {/* <div className="container-xl">
                <div className="row">
                    <div className="col-sm-3">
                        <h5 className="mb-3">Company</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.CONTACT_US}>{text.footer.information.contact[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.ABOUT_US}>{text.footer.information.about[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.FAQS}>{text.footer.information.faqs[lang]}</Link></li>
                            <li><Link to={NAVIGATION.KEYWORDS}>{text.footer.information.keywords[lang]}</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">Help</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PRIVACY_POLICY}>{text.footer.links.privacy_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PAYMENT_POLICY}>{text.footer.links.payment_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.WEBSITE_OWNER}>{text.footer.links.website_owner[lang]}</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">Resources</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.CONTACT_US}>{text.footer.information.contact[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.ABOUT_US}>{text.footer.information.about[lang]} {pageInfo.brandname}</Link></li>
                            <li><Link to={NAVIGATION.FAQS}>{text.footer.information.faqs[lang]}</Link></li>
                            <li><Link to={NAVIGATION.KEYWORDS}>{text.footer.information.keywords[lang]}</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-3">
                        <h5 className="mb-3">Legal</h5>
                        <ul className="footer-links">
                            <li><Link to={NAVIGATION.TERMS_OF_SERVICE}>{text.footer.links.tos[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PRIVACY_POLICY}>{text.footer.links.privacy_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.PAYMENT_POLICY}>{text.footer.links.payment_policy[lang]}</Link></li>
                            <li><Link to={NAVIGATION.WEBSITE_OWNER}>{text.footer.links.website_owner[lang]}</Link></li>
                        </ul>
                    </div>
                </div>
            </div> */}
            <div id="copyright" className="site-copyright">
                <div className="container-xl">
                    <p>&copy; Copyright 2023, All Rights Reserved</p>
                </div>
            </div>
        </footer>
    );
})

export {
    ScreenWithNavBar,
    ScreenWithNavBarAndFooter
}