import { NAVIGATION } from './routes';

const BASE_API = 'https://api.baholdings.vn'

const HOMEPAGE = 'http://localhost:3000/'

export {
    BASE_API,
    HOMEPAGE,
    NAVIGATION
}