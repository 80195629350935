import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { text } from 'app/localization';

import { connect } from 'react-redux';
import { pageInfoActions, authActions } from 'app/redux/actions';

import { NAVIGATION } from 'config';

import { ScreenWithNavBarAndFooter, Slider } from 'app/components';
import { EffectCoverflow } from 'swiper';

const Dashboard = props => {
    const { history, location, pageInfo, logOut } = props;
    const lang = pageInfo.display_lang;

    const team_member_list = [
        {
            id: 1,
            name: 'Quyet Vu',
            position: 'CEO & Co-Founder',
            description: 'Quyet is a visionary leader with extensive experience in the technology industry. He is passionate about leveraging NFT technology to revolutionize enterprise experiences and drive innovation. With a strong background in business development, Quyet spearheads the strategic direction of the company, ensuring its growth and success.',
            img: '/public_assets/images/home/team-quyetvu.jpg'
        },
        {
            id: 2,
            name: 'Dung Vu',
            position: 'CEO & Co-Founder',
            description: 'Dung brings a wealth of financial expertise to our team as our Chief Financial Officer. With years of experience in financial management and strategic planning, he ensures the financial health and stability of our company. Dung’s meticulous attention to detail and analytical skills drive our financial strategies, enabling us to make sound business decisions and achieve sustainable growth.',
            img: '/public_assets/images/home/team-dungvu.jpg'
        },
        {
            id: 3,
            name: 'Dong Hoang',
            position: 'CTO',
            description: 'As our Chief Technology Officer and Co-founder, Dong is the driving force behind our technological advancements. With a deep understanding of virtual building platforms and NFT technologies, he leads our development team in creating innovative solutions. Dong\'s visionary mindset and technical expertise shape the direction of our company, ensuring we stay at the forefront of the industry.',
            img: '/public_assets/images/home/team-donghoang.jpg'
        },
        {
            id: 4,
            name: 'David Ngo',
            position: 'Project Manager (Blockchain)',
            description: 'David brings extensive experience in project management and a deep understanding of blockchain technology to our team. As the Project Manager (Blockchain), he oversees the successful execution of our projects, ensuring timelines, deliverables, and budgets are met. David\'s meticulous planning, strong leadership, and expertise in blockchain ensure seamless integration of this technology into our virtual building platform. His commitment to excellence and effective communication drive the successful implementation of our blockchain initiatives.',
            img: '/public_assets/images/home/team-davidngo.jpg'
        },
        {
            id: 5,
            name: 'Harley Stevenson',
            position: 'Marketing Manager',
            description: 'Harley is a dynamic marketer with a passion for promoting innovative technologies. She leads the marketing efforts, implementing effective strategies to create awareness and drive adoption of the NFT virtual building platform. Harley\'s creativity and strategic thinking help position the company as a leader in the virtual building industry.',
            img: '/public_assets/images/home/team-harley.jpg'
        },
    ]

    const [memberCard, setMemberCard] = useState(team_member_list[0]);

    const testimonial_items = [
        {
            name: 'John Doe',
            position: 'CEO of XYZ Corp',
            avatar: '/public_assets/images/testimonial-1.jpg',
            text: 'Multinational corp unites remote teams w/ NFT-powered virtual HQ. Seamlessly collaborate, game-changer success.'
        },
        {
            name: 'John Smith',
            position: 'ABC E-commerce',
            avatar: '/public_assets/images/testimonial-2.jpg',
            text: 'Customization options blew us away. Perfect virtual showcase, wows customers and boosts engagement.'
        },
        {
            name: 'Sarah Johnson',
            position: 'DEF Software Co',
            avatar: '/public_assets/images/testimonial-3.jpg',
            text: 'Engaging virtual trade show wows attendees with immersive environment, interactive booths.'
        },
    ]

    function renderTestimonialItem(item, index) {
        return (
            <div className="testimonial-slide-item" key={'testimonial_'+index}>
                <i className="fas fa-3x fa-quote-right mb-4"></i>
                <p>&quot;{item.text}&quot;</p>
                <div className="testimonial-slide-item-footer">
                    <div className="testimonial-slide-item-avatar me-3" style={{ backgroundImage: 'url(' + item.avatar + ')' }}></div>
                    <div className="testimonial-slide-item-author">
                        <p className="testimonial-slide-item-name">{item.name}</p>
                        <p className="testimonial-slide-item-position">{item.position}</p>
                    </div>
                </div>
            </div>
        )
    }

    function renderTeamMemberList(list) {
        if (!list || list.length === 0) return;

        let team_members = [];
        list.map((item, index) => {
            team_members.push(
                <div className={`team-member ${ memberCard.id && memberCard.id === item.id ? 'active' : '' }`} key={'team_member_' + index} onClick={() => { setMemberCard(item) }}>
                    <div className="team-member-img" style={{ backgroundImage: 'url("' + item.img + '")' }}></div>
                    <div className="team-member-detail">
                        <h5>{item.name}</h5>
                        <p>{item.position}</p>
                    </div>
                </div>
            )
            return item;
        });

        return team_members;
    }

    function renderTeamMemberCard(item) {
        if (!item) return;

        return (
            <div className="team-member-card-wrapper">
                <div className="team-member-card">
                    <div className="team-member-card-img" style={{ backgroundImage: 'url("' + item.img + '")' }}></div>
                    <div className="team-member-card-detail">
                        <h5>{item.name}</h5>
                        <p className="mb-3">{item.position}</p>
                        <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <ScreenWithNavBarAndFooter>
            <section id="welcome" className="page-section welcome-section">
                <div className="container-xl">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h1 className="welcome-title">Transform your enterprise with <br className="desktop" />
                            <span className="text-primary">NFT Virtual Buildings</span></h1>
                            <p className="welcome-description mb-5">Welcome to our NFT-powered virtual building platform for enterprises. Create unique virtual spaces for offices, product showcases, and events.</p>
                            <div className="row">
                                <div className="col-md-7 mb-3 mb-md-0">
                                    <input type="email" className="form-control form-control-lg form-control-transparent" id="floatingInput" placeholder="Enter your email"></input>
                                </div>
                                <div className="col-md-5">
                                    <a href="#contact" className="btn btn-lg btn-primary w-100 h-100">Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-5">
                            <img src="/public_assets/images/home/icon-scroll.svg" width={20}></img>
                        </div>
                    </div>
                </div>
            </section>
            <section id="benefit" className="page-section benefit-section">
                <div className="container-xl">
                    <div className="benefit-section-wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <img className="img-fluid rounded w-100 mb-5 mb-md-0" src="/public_assets/images/home/benefit-img.jpg" alt=""></img>
                            </div>
                            <div className="col-md-6">
                                <div className="d-md-flex flex-column justify-content-between h-100">
                                    <div className="sec-header mb-md-0">
                                        <p className="sec-description">BENEFITS</p>
                                        <h2 className="sec-title">Future business with <br className="desktop" />NFT virtual buildings</h2>
                                    </div>
                                    <div className="mb-5 mb-md-0">
                                        <div className="benefit-item">
                                            <img className="benefit-icon me-3" src="/public_assets/images/home/icon-benefit-1.png" width={56} height={56}></img>
                                            <div className="benefit-content">
                                                <h5>Cost savings</h5>
                                                <p>With a virtual building, there's no need to rent physical office space or pay for expensive event venues. This can result in significant cost savings for your business.</p>
                                            </div>
                                        </div>
                                        <div className="benefit-item">
                                            <img className="benefit-icon me-3" src="/public_assets/images/home/icon-benefit-2.png" width={56} height={56}></img>
                                            <div className="benefit-content">
                                                <h5>Unique branding</h5>
                                                <p>By incorporating NFT artwork into your virtual building, you can create a one-of-a-kind showcase for your brand. This can help to differentiate your company from competitors and create a lasting impression on customers.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="#collection" className="fw-bold text-white">Learn more<i className="far fa-long-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="collection" className="page-section collection-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <p className="sec-description">COLLECTION</p>
                        <h2 className="sec-title">Unlocking financial opportunities <br className="desktop" />with NFT virtual buildings</h2>
                        <p className="sec-description my-5">In addition to offering digital assets, NFT virtual buildings also present prospects for income generation through leasing, selling, or renting. Owners get access to exclusive features, benefits, and an opportunity to purchase early.</p>
                        <a href="#contact" className="btn btn-lg btn-primary btn-190">Contact us</a>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="collection-item collection-item-2x2">
                                    <div className="ratio ratio-1x1">
                                        <div className="collection-img" style={{ backgroundImage: 'url(/public_assets/images/home/collection-img-2.jpg)' }}></div>
                                    </div>
                                </div>
                                <div className="collection-item collection-item-2x2 mb-4 mb-md-0">
                                    <div className="ratio ratio-1x1">
                                        <div className="collection-img" style={{ backgroundImage: 'url(/public_assets/images/home/collection-img-6.jpg)' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="collection-item collection-item-1x2 h-100">
                                    <div className="h-100">
                                        <div className="collection-img w-100 h-100" style={{ backgroundImage: 'url(/public_assets/images/home/collection-img-3.jpg)' }}></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="collection-item collection-item-2x2">
                                    <div className="ratio ratio-1x1">
                                        <div className="collection-img" style={{ backgroundImage: 'url(/public_assets/images/home/collection-img-4.jpg)' }}></div>
                                    </div>
                                </div>
                                <div className="collection-item collection-item-2x2">
                                    <div className="ratio ratio-1x1">
                                        <div className="collection-img" style={{ backgroundImage: 'url(/public_assets/images/home/collection-img-5.jpg)' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="token" className="page-section token-section">
                <div className="container-xl">
                    <div className="token-section-wrapper">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-md-flex flex-column justify-content-between h-100">
                                    <div className="sec-header mb-md-0">
                                        <p className="sec-description">OUR TOKEN</p>
                                        <h2 className="sec-title">Earn and utilize tokens</h2>
                                    </div>
                                    <div className="mb-5 mb-md-0">
                                        <div className="token-item">
                                            <img className="token-icon me-3" src="/public_assets/images/home/icon-token-1.png" width={56} height={56}></img>
                                            <div className="token-content">
                                                <h5>Earn Tokens</h5>
                                                <p>Engage with our virtual building platform to earn valuable tokens. Participate in virtual events, utilize interactive features, and contribute to the platform's growth to earn rewards.</p>
                                            </div>
                                        </div>
                                        <div className="token-item">
                                            <img className="token-icon me-3" src="/public_assets/images/home/icon-token-2.png" width={56} height={56}></img>
                                            <div className="token-content">
                                                <h5>Utilize Tokens</h5>
                                                <p>Use your earned tokens to unlock exclusive content, access premium features, and participate in the vibrant virtual economy. Trade tokens with other users to enhance your virtual building experience and maximize your benefits.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="/public_assets/LCA-Token-Whitepaper.pdf" target="_blank" className="fw-bold text-white">White Paper<i className="far fa-long-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img className="img-fluid rounded w-100 mt-5 mt-md-0" src="/public_assets/images/home/token-img.jpg" alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="team" className="page-section team-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <p className="sec-description">OUR TEAM</p>
                        <h2 className="sec-title">Meet our Team member</h2>
                        <p className="sec-description my-5">Experts driving virtual building innovation with passion and dedication. Collaborative, creative, and customer-focused, we deliver exceptional NFT-powered solutions.</p>
                        <a href="/public_assets/LCA-Token-Whitepaper.pdf" target="_blank" className="btn btn-lg btn-outline-light btn-190">White Paper</a>
                    </div>
                    <div className="sec-content">
                        <div className="row">
                            <div className="col-md-6 mb-5 mb-md-0">
                                { renderTeamMemberCard(memberCard) }
                            </div>
                            <div className="col-md-6">
                                <div className="team-member-list">
                                    { renderTeamMemberList(team_member_list) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="testimonial" className="page-section testimonial-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <p className="sec-description">TESTIMONIALS</p>
                        <h2 className="sec-title">Our customers say it best.</h2>
                        <p className="sec-description my-5">Hear from our satisfied clients and attendees who have experienced the transformative impact of our NFT virtual building platform. Discover how our immersive environments and customizable features have elevated their businesses and events.</p>
                        <div className="text-center">
                            <a href="https://twitter.com/lcasupport" target="_blank" className="btn btn-lg btn-outline-light btn-190 me-md-3 mb-3 mb-md-0">Join us on Twitter</a>
                            <a href="https://t.me/lcasupport" target="_blank" className="btn btn-lg btn-outline-light btn-190">Join us on Telegram</a>
                        </div>
                    </div>
                    <div className="sec-content">
                        <div className="testimonial-slider">
                            <Slider
                                list={testimonial_items}
                                renderItem={renderTestimonialItem}
                                overrideConfig={{
                                    modules: [EffectCoverflow],
                                    slidesPerView: 1,
                                    breakpoints: {
                                        768: {
                                            slidesPerView: 3,
                                            effect: 'slide'
                                        }
                                    },
                                    effect: 'coverflow'
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id="faq" className="page-section faq-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <p className="sec-description">FAQ</p>
                        <h2 className="sec-title">Frequently asked questions</h2>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                What exactly is an NFT-powered virtual building platform, and how does it work?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                An NFT-powered virtual building platform is a platform that allows enterprises to create their own virtual spaces that can be accessed by employees, customers, or other stakeholders. It's powered by NFTs, which can be used to represent virtual assets such as artwork, furniture, or other items that can be used to customize the virtual space.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                What are some of the benefits of using an NFT-powered virtual building platform?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Some benefits include creating a unique and immersive virtual experience that reflects your brand, increasing collaboration and engagement among employees, reducing travel and facility costs, -and creating a memorable and impactful experience for customers.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Do I need any technical skills to use the platform?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                No, you don't need any technical skills to use the platform. Our user interface is designed to be user-friendly and intuitive, so you can easily create and customize your virtual space without any coding knowledge.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                What kind of support do you offer for users?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                We offer a comprehensive support system that includes technical support, customer service, and training resources. Our team is always available to help you with any issues or questions you may have.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                How secure is the platform, and what measures do you have in place to protect user data?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                The platform is designed with security in mind, and we implement the latest security protocols and measures to ensure that user data is protected. We also conduct regular security audits and testing to identify and address any vulnerabilities.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact" className="page-section contact-section">
                <div className="container-xl">
                    <div className="sec-header text-center">
                        <p className="sec-description">CONTACT US</p>
                        <h2 className="sec-title">Step into the Future of Business <br className="desktop" />with our NFT-Powered Virtual Building</h2>
                        <p className="sec-description mt-5">Contact us for a free trial or schedule a demo today to experience the power of NFTs for your enterprise virtual building needs.</p>
                    </div>
                    <div className="sec-content">
                        <div className="row justify-content-center">
                            <div className="col-md-5">
                                <div className="mb-4">
                                    <label htmlFor="fullname" className="form-label">Your Name</label>
                                    <input type="text" className="form-control form-control-lg form-control-transparent" id="fullname"></input>
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="email" className="form-label">Your Email</label>
                                    <input type="email" className="form-control form-control-lg form-control-transparent" id="email"></input>
                                </div>
                                <div className="text-center">
                                    <a className="btn btn-lg btn-primary btn-190">Contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ScreenWithNavBarAndFooter>
    )
}

const mapStateToProps = (state) => {
    const { pageInfo } = state;
    return { pageInfo }
}

const actionCreators = {
    logOut: authActions.logout
}

export default connect(mapStateToProps, actionCreators)(Dashboard);