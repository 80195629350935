export const text = {
    app_alert: {
        notice: {
            vi: 'Thông báo',
            en: 'Notice'
        }
    },
    auth: {
        login: {
            vi: 'Đăng nhập',
            en: 'Login',

            username: {
                vi: 'Tên đăng nhập',
                en: 'Username',
            },
            password: {
                vi: 'Mật khẩu truy cập',
                en: 'Password',
            },
            forgot_password: {
                vi: 'Quên mật khẩu?',
                en: 'Forgot password?',
            },
            register: {
                vi: 'Chưa có tài khoản? Đăng ký',
                en: 'Register an account',
            },
            terms_of_services: {
                vi: 'Điều khoản sử dụng',
                en: 'Terms of Services',
            },
            field_can_not_be_blank: {
                vi: 'Tên đăng nhập hoặc mật khẩu không được để trống!',
                en: 'Username or password can not be blank!'
            },
        },
        register: {
            vi: 'Đăng ký tài khoản',
            en: 'Register an account',
            
            full_name: {
                vi: 'Họ tên đầy đủ',
                en: 'Full Name',
                de: 'Full Name',
            },
            email: {
                vi: 'Email',
                en: 'Email',
                de: 'Email',
            },
            phone_number: {
                vi: 'Số điện thoại',
                en: 'Phone Number',
                de: 'Phone Number',
            },
            password: {
                vi: 'Mật khẩu đăng nhập',
                en: 'Password',
                de: 'Password',
            },
            province: {
                vi: 'Tỉnh/Thành phố',
                en: 'City/Province',
                de: 'City/Province'
            },
            district: {
                vi: 'Quận/Huyện',
                en: 'District',
                de: 'Kreis'
            },
            please_select: {
                vi: 'Vui lòng chọn',
                en: 'Please select',
                de: 'Bitte auswählen'
            },
            address: {
                vi: 'Địa chỉ',
                en: 'Address',
                de: 'Address',
            },
            register: {
                vi: 'Đăng ký',
                en: 'Register',
                de: 'Registrieren',
            },
            code_ref: {
                vi: 'Mã giới thiệu',
                en: 'Referral Code',
                de: 'Referenzcode',
            }
        },
        forgot_password: {
            vi: 'Quên mật khẩu',
            en: 'Forgot password',

            enter_registered_phone_number: {
                vi: 'Nhập số điện thoại đã đăng ký',
                en: 'Enter your registered phone number',
            },
            send_request: {
                vi: 'Gửi yêu cầu',
                en: 'Send request'
            },
            field_can_not_be_blank: {
                vi: 'Tên đăng nhập không được để trống!',
                en: 'Username can not be blank!'
            }
        },
        return_to_login: {
            vi: 'Quay lại trang đăng nhập',
            en: 'Return to login page'
        }
    },
    top_nav_bar: {
        account: {
            vi: 'Tài khoản',
            en: 'Account'
        },
        logout: {
            vi: 'Đăng xuất',
            en: 'Log out'
        },
        login: {
            vi: 'Đăng nhập',
            en: 'Log in'
        }
    },
    footer: {
        links: {
            vi: 'ĐƯỜNG DẪN',
            en: 'LINKS',

            tos: {
                vi: 'Điều khoản dịch vụ',
                en: 'Terms of Service'
            },
            privacy_policy: {
                vi: 'Chính sách bảo mật',
                en: 'Privacy Policy'
            },
            payment_policy: {
                vi: 'Chính sách thanh toán',
                en: 'Payment Policy'
            },
            website_owner: {
                vi: 'Thông tin chủ sở hữu',
                en: 'Website Owner'
            }
        },
        information: {
            vi: 'THÔNG TIN',
            en: 'INFORMATION',

            contact: {
                vi: 'Liên hệ',
                en: 'Contact'
            },
            about: {
                vi: 'Giới thiệu về',
                en: 'About'
            },
            faqs: {
                vi: 'Câu hỏi thường gặp',
                en: 'FAQs'
            },
            keywords: {
                vi: 'Từ khoá nổi bật',
                en: 'Keywords'
            }
        },
    },
}