import { BASE_API } from 'config'

const API = {
    // Authentication
    'LOGIN': BASE_API + '/login',
    'REGISTER': BASE_API + '/register',

    // Homepage
    'FETCH_PAGE_INFO': BASE_API + '/website-info',
}

export default API;